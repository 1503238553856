import {LANGUAGE} from './constants'
export default defineI18nConfig(async () => {

  const defaultLang = 'vi'

  const messages: any = {}
  for (const code of LANGUAGE) {
    const locale = await import(`~/locales/${code}.json`).catch(
      () => {}
    )
    messages[code] = { ...locale }
  }
  return {
    legacy: false,
    globalInjection: true,
    locale: defaultLang,
    messages,
  }
})
